@import './../../variables.scss';

.TradeFairPointsOverview {
    margin: 0 1.2rem 8.2rem 1.2rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    img.logo {
        width: 24rem;
        margin: 2.4rem;
    }

    img.spaarsaldo {
        width: 100%;
        max-width: 100rem;
        margin: 2.4rem;
    }

    h1 {
        font-size: 3.3rem;
        font-weight: 700;
        margin: 2.4rem;
        text-transform: uppercase;
    }

    ul {
        font-size: 2rem;

        li {
            margin: 1rem;
        }
    }

    .points {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1.2rem;
        font-size: 3.5rem;
        margin: 4rem;
        flex-wrap: wrap;
    }

    .carousel {
        display: flex;
        flex-flow: row wrap;
        align-items: flex-start;
        justify-content: center;
        gap: 2.5rem;
        margin: 2rem auto;

        img {
            width: auto;
            height: 30rem;
        }
    }

    .footer {
        margin-top: 3rem;
        font-size: 1.4rem;

        .rules {
            margin-top: 5rem;
            font-size: 1.2rem;
            display: flex;
            gap: 4rem;


        }

    }
}